.container {
    margin: 0 auto;

}

.row {
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
}

.title {
    text-align: center;
    margin-bottom: 40px;
}

.row-background-gray {
  background-color: #f7f7f7;
}

.row-background-white {
  background-color: #fff;
}

.col {
    margin-top: 50px;
}

.deploy-icon-img {
    width: 90%;
    height: 90%;
}

.custom-card {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}

.custom-card .ant-card-body {
  padding: 0;
    display: flex;
  justify-content: center;
  align-items: center;
}


