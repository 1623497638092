.ant-form-item.auto-login {
  margin-top: 0;
    margin-bottom: 5px;
}

.ant-checkbox-wrapper {
  display: flex;
    justify-content: end;
  align-items: center;
    margin-right: 5px;
}

.separator {
    margin-top: 10px;
    margin-bottom: 10px;
  border-top: 1px solid rgba(217, 216, 216, 0.97);
}
