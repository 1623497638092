.container {
    padding-top: 60px;
    margin: 0 auto;

}

.row {
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
}

.title {
    text-align: center;
    margin-bottom: 40px;
}

.row-background-gray {
  background-color: #f7f7f7;
}

.row-background-white {
  background-color: #fff;
}



#chatbot-char {
    height: auto;
    width: 85%;
    justify-content: center;
  align-items: center;
}

#chatbot-flow  {
    height: auto;
    width: 90%;
    justify-content: center;
  align-items: center;
}

.chatbot-card-title {
    color: #0088ff;
    font-size: 24px;
    width: 80%;

}

.chatbot-card-text {
    color: black;
    font-size: 18px;
    width: 80%;
}

.col {
    margin-top: 50px;
}

.chatbot-img {
    width: 80%;
    height: 300px;
}