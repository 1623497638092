.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #0b1a32;
  color: #fff;
  z-index: 1000;
  transition: all 0.3s ease-in-out;

}



.logo {
  width: 100%;
  display: flex;
  font-size: 22px;
  font-weight: bold;
  justify-content: flex-start;
}

.userinfo{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
}

.navbar:hover {
  height: 70px;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.navbar-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
   background-color: #0b1a32;
  height: auto;
  flex-grow: 1;
}

.navbar-menu-item {
  position: relative;
  margin-right: 40px;
  cursor: pointer;
  font-size: 16px;
}

.navbar-menu-item:hover {
  color: #fff;
}

.navbar-menu-item:hover::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 3px;
  background-color: #fff;
}

.navbar-menu-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  padding: 10px;
  background-color: #0b1a32;
  border: none;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.navbar-menu-item:hover .navbar-menu-dropdown {
  opacity: 1;
  pointer-events: auto;
}

.navbar-menu-dropdown-item {
  display: block;
  padding: 5px 0;
  color: #fff;
  font-size: 14px;
}

.navbar-menu-dropdown-item:hover {
  color: #fff;
}

