.container {
    padding-top: 60px;
    margin: 0 auto;

}

.row {
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
}

.media-col {
    margin-bottom: 10px;
}

.title {
    text-align: center;
    margin-bottom: 40px;
}

.row-background-gray {
  background-color: #f7f7f7;
}

.row-background-white {
  background-color: #fff;
}



#chatbot-char {
    height: auto;
    width: 85%;
    justify-content: center;
  align-items: center;
}

#chatbot-flow  {
    height: auto;
    width: 90%;
    justify-content: center;
  align-items: center;
}

.media-card {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.card-title {
    color: #0088ff;
    font-size: 20px;
}

.card-text {
    color: black;
    font-size: 14px;
}
