.container {
    margin: 0 auto;
}

.home-row {
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 15%;
    padding-right: 15%;
}

.title {
    text-align: center;
    margin-bottom: 60px;
}

.row-background-gray {
  background-color: #f7f7f7;
}

.row-background-white {
  background-color: #fff;
}

.card-icon {
    width: 30%;
    margin-bottom: 30px;
}

.home-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 30px;
    color: black;
}

.card-text {
    color: #8d8b8b;
    width: 80%;
    font-size: 12pt;
}

.text {
   color: darkgrey;
    font-size: 12pt;
    margin-bottom: 10px;
}

.col {
    margin-top: 150px;
}

.card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 60%;
    padding-top: 60px;
    padding-bottom: 30px;
}

.home-img {
    width: 100%;
}

