.footer-text {
    margin-bottom: 8px;
     color: white;
    text-align: left;
}

.ant-btn.footer-text:hover {
  background-color: #f5f5f5;
  color: #333;
}
