.container {
    margin: 0 auto;

}

.row {
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
}

.title {
    text-align: center;
    margin-bottom: 40px;
}

.row-background-gray {
  background-color: #f7f7f7;
}

.row-background-white {
  background-color: #fff;
}

.col {
    margin-top: 50px;
}

.icon-img {
    width: 45px;
}

.card-img {
    width: 60%;
}

.card-title {
    margin-top: 5%;
}