

.container {
    margin: 0 auto;
    background: #112b3c;
}

.assistant-row {
    margin-top: 60px;
    padding-top: 40px;
    padding-bottom: 80px;
    padding-left: 5%;
    padding-right: 5%;
}

.role-col{
    padding-left: 10%;
    margin-bottom: 20px;
}
