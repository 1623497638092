.container {
    margin: 0 auto;

}

.about-row {
    padding-top: 80px;
    padding-bottom: 100px;
    padding-left: 20%;
    padding-right: 20%;
}

.about-title {
    color: #00FFFF;
}

.about-paragraph {
    margin-top: 50px;
    font-size: 20px; /* 改变字体大小 */
  color: #333; /* 改变颜色为深灰色 */
}

.about-card {
    width: 100%;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row-background-gray {
  background-color: #f7f7f7;
}

.row-background-white {
  background-color: #fff;
}