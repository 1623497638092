

.login-row {
    display: flex;
    margin-top: 200px;
}

.login-container {
    width: 40%;
    background-color: aliceblue;
}

@media screen and (max-width: 600px) {
  .login-container {
    width: 70%;
  }
}

@media screen and (max-width: 400px) {
  .my-component {
    width: 90%;
  }
}
